import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const RouteView = {
    name: 'RouteView',
    render: h => h('router-view')
  }
const routes = [{
    path: '/',
    name: 'index',
    component: RouteView,
    meta: {
      title: 'menu.home'
    },
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'FirstPage',
        component: () => import('@/views/FirstPage'),
        meta: {
          title: '大屏首页',
        }
      },
      {
        path: '/XyyBasicSetting/set/SetBigScreet',
        name: 'SetBigScreet',
        component: () => import('@/views/SetBigScreet'),
        meta: {
          title: '设置大屏',
        }
      },
      {
        path: '/XyyBasicSetting/set/PreviewBigScreet',
        name: 'PreviewBigScreet',
        component: () => import('@/views/PreviewBigScreet'),
        meta: {
          title: '预览大屏'
        }
      },
      {
        path: '/XyyBasicSetting/set/Banner',
        name: 'Banner',
        component: () => import('@/views/Banner'),
        meta: {
          title: '轮播大屏'
        }
      }
    ]
    },
    {
      path: '*',
      redirect: '/',
      hidden: true
    }
  ]
   /*  {
        path: '/',
        name: 'SetBigScreet',
        component: ()=>('@/views/SetBigScreet'),
        meta: {
            title: '设置大屏'
        }
    },
    {
        path: '@/views/PreviewBigScreet',
        name: 'PreviewBigScreet',
        component: RouteView,
        meta: {
            title: '预览大屏'
        }
    } */
const router = new VueRouter({
    routes,
    mode: 'hash'
})
export default router