import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router/index'
import { VueAxios } from './utils/request'
import scroll from 'vue-seamless-scroll'
import VueStorage from 'vue-ls'
import animate from 'animate.css'
Vue.config.productionTip = false
import 'ant-design-vue/dist/antd.css';
import 'swiper/dist/css/swiper.css'
import './permission'
import './core/lazy_use'
Vue.use(VueAwesomeSwiper)
Vue.use(VueAxios)
Vue.use(scroll)
Vue.use(VueStorage)
new Vue({
  el: '#app',
  router,
  strict: false,
  render: h => h(App),
}).$mount('#app')
Vue.prototype.$getBase64 = function (url, callback) {
  var Img = new Image()
  var dataURL = '';
  Img.src = url + '?v=' + Math.random()
  Img.setAttribute('crossOrigin', 'Anonymous')
  Img.onload = function () {
    var canvas = document.createElement('canvas')
    var width = Img.width
    var height = Img.height
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(Img, 0, 0, width, height)
    dataURL = canvas.toDataURL('image/jpeg')
    return callback ? callback(dataURL) : null;
  }
}