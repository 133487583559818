<template>
  <a-config-provider :locale="zh_CN">
  <div id="app">
     <router-view/>
  </div>
</a-config-provider>
</template>
<script>
   import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
   import moment from 'moment';
  import 'moment/locale/zh-cn';
  moment.locale('zh-cn');
export default {
  name: 'App',
  components: {},
  data() {
      return {
        zh_CN,
      };
    }
}
</script>

<style>
#app{
  height: 100%;
}
::-webkit-scrollbar {
  background-color: #282828;
}
::-webkit-scrollbar-thumb  {
  background-color: #535353;
  border-radius: 10px;
}
::-webkit-scrollbar-track-piece {
  background-color: #282828;
}
::-webkit-scrollbar-corner {
  background-color: #282828;
}
</style>
