import Vue from 'vue'
import {
    Modal,
    Icon,
    Card,
    Button,
    Empty,
    Spin,
    Popover,
    Carousel,
    Upload,
    message,
    Popconfirm,
    ConfigProvider,
    Input,
    Select,
    Form,
    Slider,
    Table,
    Divider,
    InputNumber,
    Radio,
    Pagination
} from 'ant-design-vue'
Vue.use(Modal)
Vue.use(Icon)
Vue.use(Card)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Spin)
Vue.use(Popover)
Vue.use(Carousel)
Vue.use(Upload)
Vue.use(Popconfirm)
Vue.use(ConfigProvider)
Vue.use(Input)
Vue.use(Select)
Vue.use(Form)
Vue.use(Slider)
Vue.use(Table)
Vue.use(Divider)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(Pagination)
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$info = Modal.info
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message