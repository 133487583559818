import router from './router'
// import { paramReq } from '@/api/other'
// import api from '@/api'

router.beforeEach((to, from, next) => {
  next()
})

router.afterEach(() => {
})
