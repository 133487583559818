const api = {
  // BaseHost: 'https://test.xueyoyo.com',
  BaseHost: 'https://www.xueyoyo.com.cn',
  // BaseHost: 'http://192.168.0.27:9999',
  // BaseHost: 'http://d4233u9527.wicp.vip:40179',
  // BaseHost: 'http://192.168.1.20:9999',
  isBuy: true,
  version: '1.0.0',
  // bigscreenLeft: '/client/bigscreen/left',
  // bigscreenLeft: '/client/bigscreen/v2/left',
  modelsQuery: '/admin/bigScreen/component/models/query',
  // bigscreenLeftBottom: '/client/bigscreen/left/bottom',
  // bigscreenLeftBottom: '/client/bigscreen/v2/left/bottom',
  // warningStu: '/client/bigscreen/assessment/warning/stu',
  warningStu: '/client/bigscreen/v2/assessment/warning/stu',
  // bigscreenLeftV2: '/client/bigscreen/left/v2',
  bigscreenLeftV2: '/client/bigscreen/v2/left/v2',
  // bigscreenStudyProgressV2: '/client/bigscreen/study/progress/v2',
  bigscreenStudyProgressV2: '/client/bigscreen/v2/study/progress/v2',
  // bigscreenTeacherMateriaPies: '/client/bigscreen/teacher/materia/pies',
  bigscreenTeacherMateriaPies: '/client/bigscreen/v2/teacher/materia/pies',
  // bigscreenStudyHeros: '/client/bigscreen/study/heros',
  bigscreenStudyHeros: '/client/bigscreen/v2/study/heroV3',
  // bigscreenStudyAssessmentV2: '/client/bigscreen/study/assessment/v2',
  bigscreenStudyAssessmentV2: '/client/bigscreen/v2/study/assessment/v2',
  // bigscreenTeacherMateria: '/client/bigscreen/teacherMateria',
  bigscreenTeacherMateria: '/client/bigscreen/v2/teacherMateria',
  // bigscreenWarningTeacher: '/client/bigscreen/assessment/warning/teacher',
  bigscreenWarningTeacher: '/client/bigscreen/v2/assessment/warning/teacher',
  bigScreenUIList: '/admin/bigScreen/ui/list',
  bigScreenUIUpdateName: '/admin/bigScreen/ui/update/name',
  bigScreenUIDel: '/admin/bigScreen/ui/del',
  bigscreenWarningReport: '/client/bigscreen/weather/report',
  bigscreenUiPublish: '/admin/bigScreen/ui/publish',
  bigscreenUiGet: '/client/bigscreen/ui/get',
  xuekeSimpleList: '/admin/xueke/simple/list',
  gradeListSimple: '/admin/gradeList/simple',
  assessmentSettingListSimple: '/admin/bigScreen/assessmentContent/list/simple',
  bigScreenUiSsave: '/admin/bigScreen/ui/save',
  bigScreenInSameModel: '/admin/bigScreen/component/component/other/inSameModel',
  bigScreenShowType2: '/client/bigscreen/v2/study/assessment/v2/showType2',
  bigScreenWeek: '/client/bigscreen/v2/assessment/week',
  bigScreenHomeWork: '/client/bigscreen/v2/homeWork/finish',
  bigScreenUploadQuestion: '/client/bigscreen/v2/teacher/uploadQuestion',
  bigScreenTeacherMateriaKeshi: '/client/bigscreen/v2/teacherMateria/keshi',
  bigScreenTeacherHomework: '/client/bigscreen/v2/teacher/homework',
  bigScreenTeacherGoodKeshi: '/client/bigscreen/v2/teacher/good/keshi',
  bigScreenErrorCorrecting: '/client/bigscreen/v2/errorCorrecting',
  bigScreenZixishiStudying: '/client/bigscreen/v2/zixishi/studying',
  bigScreenZixishiStudyingQuestionCountSort: '/client/bigscreen/v2/zixishi/studying/question/count/sort',
  bigScreenZixishiStudyingQuestionIntegralSort: '/client/bigscreen/v2/zixishi/studying/question/integral/sort',
  bigScreenZixishiClassRoom: '/client/bigscreen/v2/zixishi/classRoom',
  bigScreenZixishiClassRoomKeshi: '/client/bigscreen/v2/zixishi/classRoom/keshi',
  bigScreenZixishiStudyingTop: '/client/bigscreen/v2/zixishi/studying/top',
  bigScreenZixishiStudyingQuestionType: '/client/bigscreen/v2/zixishi/studying/question/type',
  bigScreenZixishiStudyingQuestionRightSort: '/client/bigscreen/v2/zixishi/studying/question/right/sort',
  bigScreenZixishiOutSideLearningCurrent: '/client/bigscreen/v2/zixishi/outSide/learning/current',
  bigScreenZixishiOutSideLearningCount: '/client/bigscreen/v2/zixishi/outSide/learning/count',
  bigScreenZixishiOutSideLearningIntegral: '/client/bigscreen/v2/zixishi/outSide/learning/integral',
  bigScreenZixishiOutSideLearningCountLine: '/client/bigscreen/v2/zixishi/outSide/learning/count/line',
  bigScreenZixishiOutSideLearningRightLine: '/client/bigscreen/v2/zixishi/outSide/learning/right/line',
  bigScreenBclassLearningCount: '/client/bigscreen/v2/bclass/learning/count',
  // 大屏配置
  bigScreenUrlChange: '/admin/bigScreen/url/change',
  bigScreenCreate: '/admin/bigScreen/create',
  getToken: '/admin/token',
  authInfo: '/admin/auth/info',
  assessmentLuoshi: '/client/bigscreen/v2/assessment/luoshi',
  assessmentLuoshiZouban: '/client/bigscreen/v2/assessment/luoshi/zouban',
  assessmentSettingSimple: '/admin/assessment/setting/list/simple',
  assessmentUploadCount: '/client/bigscreen/v2/assessment/upload/count',
  batchStudy: '/client/bigscreen/v2/batch/study',
  scrollShowQuery: '/admin/bigScreen/scroll/show/query',
  scrollShowSet: '/admin/bigScreen/scroll/show/set',
  scrollShowDel: '/admin/bigScreen/scroll/show/del',
  uiListBySchool: '/admin/bigScreen/ui/list/bySchool',
  subSchoolPeopleCount: '/client/bigscreen/v2/subSchool/peopleCount',
  subSchoolMotto: '/client/bigscreen/v2/subSchool/Motto',
  padUnlawful: '/client/bigscreen/v2/pad/unlawful',
  padgroupByClassUnlawful: '/client/bigscreen/v2/pad/unlawful/groupByClass',
  electronicCardListPage:  '/admin/bigScreen/ui/electronicCardListPage',
  // 电子班牌
  getBulletinBoardContent: '/classBoard/getBulletinBoardContent',
  classBoardExcellentList: '/classBoard/excellentList',
  classBoardHeros: '/classBoard/heros',
  bclassSimple: '/admin/cascader/bclass/simple/bySubSchool',
  getGgByPageForWeb: '/web/stu/getGgByPageForWeb',
  getStuLeavesByBclassId: '/classBoard/getStuLeavesByBclassId',
  getGgSubByPageForWeb: '/web/stu/getGgSubByPageForWeb',
  getPraiseInfo: '/classBoard/getPraiseList'
}
export default api
