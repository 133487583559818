import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import {
  VueAxios
} from './axios'
import api from '@/api/index'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  baseURL: api.BaseHost,
  timeout: 10000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    console.log('进入异常拦截处理器 error=', error)
    const data = error.response.data
    // 从 localstorage 获取 token
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    // if (error.response.code !== 0) {
    //   notification.error({
    //     message: '操作失败',
    //     description: data.errMsg
    //   })
    // }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  } else {
    console.log('error.response 判断未进入', error)
    if (error.message && error.message !== undefined) {
      if (error.message.indexOf('timeout') > -1) {
       /*  notification.error({
          message: '请求超时',
          description: '请求超时,请稍后'
        }) */
      } else {
        // Network Error
        notification.error({
          message: '请求出现异常',
          description: error.message
        })
      }
    }
    if (error.message === undefined) {
      error.message = '请求中断'
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
 /*  config.cancelToken = new axios.CancelToken(cancel => { // 在发送请求设置cancel token
    store.commit('setAxiosArr', {
      cancelToken: cancel
    })
  }) */
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
